<template>
    <v-container class="base container-fluid">
        <v-row v-if="loading">
            <v-col>
                <div v-if="loading" class="d-flex justify-center align-center pa-8">
                    <v-progress-circular :size="35"
                                        color="primary"
                                        indeterminate />
                </div>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <div class="btn selected">Importação de ativos.</div>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-select label="Selecione um usuário"
                          :items="users"
                          item-text="name"
                          item-value="user"
                          v-model="selectedUser"
                          :menu-props="{ bottom: true, offsetY: true }"
                          placeholder="Selecione um usuário"
                          color="secondary"
                          @change="userChanged($event)"
                          return-object
                          required
                          outlined
                          rounded
                          clearable
                          dense></v-select>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-btn class="btn"
                       :disabled="noImport"
                       @click="doPluggy">Iniciar importação.</v-btn>
            </v-col>
        </v-row>
        <v-row>
            <v-col v-if="item_id">
                Importado: {{ item_id }}
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-btn class="out-btn red-btn"
                       :disabled="loading"
                       @click="doLogout">SAIR</v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>

<style scoped>
.base {
    padding: 1em;
}
.service-selector {
    display: flex;
    flex-direction: row;
}
.btn {
    padding: 1em;
    border: 2px solid rgba(0,0,224,0.5);
    color: rgba(0,0,224,0.5);
    border-radius: 27px;
    font-weight: bold;
    font-size: 1em;
    cursor: pointer;
    min-width: 100%;
    width: 100%;
}
.out-btn {
    padding: 1em;
    border: 2px solid rgba(224,0,0,0.5);
    font-weight: bold;
    color: rgba(224,0,0,0.5);
    border-radius: 27px;
    font-weight: bold;
    font-size: 1em;
    cursor: pointer;
    min-width: 100%;
    width: 100%;
}
.btn:nth-child(1) {
    margin-left: 0;
}
.btn:last-child {
    margin-right: 0;
}
.btn.selected {
    background-color: rgba(0,0,224,0.5);
    color: white;
    text-align: center;
}
</style>

<script lang="js">
import EventBus     from '../../bus/bus';
import client       from '../../../services/PluggyService';

export default {
    data                    : () => ({
        client,
        loading             : false,
        users               : [],
        selectedUser        : undefined,
        selection           : 0,
        connect_token       : undefined,
        item_id             : undefined,
        item                : undefined
    }),
    methods                 : {
        getServiceClass     (index) {
            var selected = this.selection === index;
            var tail     = selected ? ' selected' : '';
            var head     = 'btn btn-';
            switch (index) {
                case 0: return `${head}first${tail}`;
                default: return `${head}generic${tail}`;
            }
        },
        getAssociatedUsers  () {
            const user  = this.$store.getters.$user;
            const id    = user && user.id;
            if (!id) {
                this.$toast.error('Falha de identificação. Pode ser preciso refazer a autenticação.');
                return;
            }
            if (this.loading) { return; }
            this.loading = true;
            return this.client.getAssociatedUsers(id)
                .then((resp) => {
                    this.loading = false;

                    if (!resp.success) {
                        this.$toast.error(resp.message);
                        return;
                    }

                    this.users.length = 0;
                    this.users.push(...resp.content);
                    const prev = this.$store.getters.$selectedUser;
                    if (prev && this.users.length && this.users.some((u) => u.id === prev.id)) {
                        this.selectedUser = prev;
                    } else {
                        this.$store.dispatch('PUT_ID_SELECTED_USER', undefined);
                        this.$store.dispatch('PUT_SELECTED_USER', undefined);
                    }
                });
        },
        setSelection        (index) {
            this.selection = index;
        },
        ebReload            (data) {
            if (!data) { return; }
            this.selection = 0;
        },
        userChanged         ($event) {
            this.$store.dispatch('PUT_ID_SELECTED_USER', this.selectedUser && this.selectedUser.id);
            this.$store.dispatch('PUT_SELECTED_USER', this.selectedUser);
        },
        doPluggy            () {
            this.item_id    = undefined;
            this.item       = undefined;
            const src       = 'https://cdn.pluggy.ai/pluggy-connect/v2.3.1/pluggy-connect.js';
            const scripts   = [].slice.apply(document.querySelectorAll('script'), [0]);
            if (!scripts.some((s) => s.getAttribute('src') === src)) {
                var include = document.createElement('script');
                include.setAttribute('src', src);
                try {
                    document.head.appendChild(include);
                    include.addEventListener('load', () => {
                        this.getToken();
                    });
                } catch (reason) {
                    this.$toast.error(reason);
                }
            } else {
                this.getToken();
            }
        },
        getToken            () {
            let uId = this.$store.getters.$idSelectedUser;
            if (!uId) {
                this.$toast.error('Usuário não identificado.');
                return;
            }
            this.loading    = true;
            return this.client.createPluggyToken(uId)
                .then((resp) => {
                    this.loading = false;
                    if (!resp.success) {
                        this.$toast.error(resp.message);
                        return;
                    }
                    this.connect_token = resp.content;
                    return this.doAuth();
                });
        },
        doAuth              () {
            let uToken = this.$store.getters.$idSelectedUser;
            if (!uToken) {
                this.$toast.error('Usuário não identificado.');
                return;
            }
            if (!this.connect_token) {
                this.$toast.error({ message: 'Falha ao acessar a Kristal!' });
                return;
            }
            this.item_id        = undefined;
            this.item           = undefined;
            const connect       = new PluggyConnect({
                connectToken    : this.connect_token,
                includeSandbox  : true,
                products        : ["INVESTMENTS", "INVESTMENTS_TRANSACTIONS", "IDENTITY"],
                onSuccess       : (data)    => {
                    this.item_id = data.item.id;
                    this.item    = data.item;
                },
                onError         : (reason)  => {
                    this.$toast.error(reason);
                }
            });
            connect.init();
        },
        doLogout            () {
            this.client.doLogout(this.$router);
        }
    },
    computed                : {
        noImport            : {
            get : function () { return this.loading || (!this.selectedUser); }
        }
    },
    created                 () {
        EventBus.$on('selectedUserChanged', this.ebReload);
    },
    mounted                 () {
        EventBus.$emit("selectedUserChanged", true);
        this.getAssociatedUsers();
    },
    beforeDestroy           () {
        EventBus.$off('selectedUserChanged', this.ebReload);
    }
}
</script>