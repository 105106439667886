import Vue from "vue";

const LOAD_DEFAULTS         = {
    showLoading         : true,
    showException       : true,
    isLogoff            : false
};
const MOCKSTORE             = { commit: () => {} };

function getMessage         (reason) {
    if (!reason) { return 'Erro não identificado.' }
    return reason.body ?
        reason.body.message || reason.body.Message || reason.message || 'Erro não identificado.' :
        reason.status ?
        reason.statusText :
        reason.message || reason.toString() || 'Erro não identificado.';
}
function getVueStore        () {
    const app = document.querySelector('#app');
    return (app && app.$store) || (app.__vue__ && app.__vue__.$store) || MOCKSTORE;
}

export default {
    getHeaders              (method, loading, isLogoff) {
        let result  = {
            Accept                          : "application/json",
            "Access-Control-Allow-Origin"   : "*",
            "Access-Control-Allow-Methods"  : "GET, POST, PATCH, PUT, DELETE, OPTIONS",
            "Access-Control-Allow-Headers"  : "Origin, Content-Type, X-Auth-Token",
            showLoading                     : loading ? "1" : "0"
        };
        if (method !== "GET") { result['Content-Type'] = 'application/json; charset=utf-8' }

        const token = sessionStorage.getItem("token");
        if (isLogoff) {
            result['X-Spent-Token'] = token;
        } else if (token) {
            result['Authorization'] = `Bearer ${token}`;
        }

        return result;
    },
    callRest                (method, path, body, options) {
        const { showException, showLoading, isLogoff } = options || {
            showException: true,
            showLoading  : true
        };
        const urlRoot       = process.env.VUE_APP_BASE_URL;
        const headers       = this.getHeaders(method, showLoading, isLogoff);
        const event         = {
            showLoading,
            requestId       : new Date().getTime(),
            payload         : body
        };

        if (Vue.$globalEvent) {
            Vue.$globalEvent.$emit("httpRequestStart", event);
        }

        return Vue.http({ method, body, headers, url: `${urlRoot}${path}` })
            .then((resp) => {
                if (Vue.$globalEvent) {
                    Vue.$globalEvent.$emit("httpRequestEnd", resp);
                }
                const store = getVueStore();
                const auth1 = resp.headers.map['x-set-authorization'];
                const auth2 = resp.headers.map['x-set-authorization-valid-to'];
                if (auth1 && auth1.length) {
                    store.commit('SET_CURRENT_TOKEN', auth1[0]);
                    store.commit('SET_EXPIRES_TOKEN', auth2[0]);
                }
                return resp.body || resp
            })
            .catch((reason) => {
                const gevent = Vue.$globalEvent;
                if (gevent) {
                    gevent.$emit("httpRequestEnd", reason);
                }
                Object.assign(reason, { method, showException });
                throw reason;
            });
    },
    callGet                 (path, body, options = LOAD_DEFAULTS) {
        return this.callRest('GET', path, body, options);
    },
    stdGet                  (path, body, options = LOAD_DEFAULTS) {
        return this.callGet(path, body, options)
            .then((resp) =>
                ({
                    success: true,
                    content: resp.content
                }))
            .catch((reason) =>
                ({
                    success: false,
                    message: getMessage(reason),
                    reason
                }));
    },
    callPost                (path, body, options = LOAD_DEFAULTS) {
        return this.callRest('POST', path, body, options);
    },
    stdPost                 (path, body, options = LOAD_DEFAULTS) {
        return this.callPost(path, body, options)
            .then((resp) =>
                ({
                    success: true,
                    content: resp.content
                }))
            .catch((reason) =>
                ({
                    success: false,
                    message: getMessage(reason),
                    reason
                }));
    },
    callPut                 (path, body, options = LOAD_DEFAULTS) {
        return this.callRest('PUT', path, body, options);
    },
    stdPut                  (path, body, options = LOAD_DEFAULTS) {
        return this.callPut(path, body, options)
            .then((resp) =>
                ({
                    success: true,
                    content: resp.content
                }))
            .catch((reason) =>
                ({
                    success: false,
                    message: getMessage(reason),
                    reason
                }));
    },
    callDel                 (path, body, options = LOAD_DEFAULTS) {
        return this.callRest('DELETE', path, body, options);
    },
    stdDel                  (path, body, options = LOAD_DEFAULTS) {
        return this.callDel(path, body, options)
            .then((resp) =>
                ({
                    success: true,
                    content: resp.content
                }))
            .catch((reason) =>
                ({
                    success: false,
                    message: getMessage(reason),
                    reason
                }));
    },
    async doLogin           (store, user, pwd) {
        try {
            const model         = { Email: user, Password: pwd };
            const req           = await this.callPost("user/token/importpluggy?no_cache=true", model);
            const userModel     = JSON.stringify(req.content.user);
            const token         = req.content.token;
            const validTo       = req.content.validTo;
            store.commit("SET_CURRENT_USER", userModel);
            store.commit("SET_CURRENT_TOKEN", token);
            store.commit("SET_EXPIRES_TOKEN", validTo);
        } catch (reason) {
            sessionStorage.removeItem("token");
            sessionStorage.removeItem("user");
            sessionStorage.removeItem("expires");
            throw reason;
        }
    },
    async doLogout          (router) {
        try {
            const resp = await this.stdGet('user/token/free');
            if (resp.success) {
                sessionStorage.removeItem("token");
                sessionStorage.removeItem("expires");
                sessionStorage.removeItem("user");
            }
        } finally {
            if (!(window && window.location.pathname === '/auth')) {
                router.push("/auth");
            }
        }
    },
    async doLogoff          (router) {
        await this.stdPost('user/token/clean', null, { isLogoff: true });
        sessionStorage.removeItem("token");
        sessionStorage.removeItem("expires");
        sessionStorage.removeItem("user");
        if (!(window && window.location.pathname === '/auth')) {
            router.push("/auth");
        }
    },
    createPluggyToken       (userId) {
        return this.stdPost('integration/pluggy/connect_token', JSON.stringify(userId));
    },
    requestInvestments      (item, userId) {
        return this.stdPost(`integration/pluggy/investments/full/${userId}`, item);
    },
    getStoredInvestments    (userId) {
        return this.stdGet(`integration/pluggy/investments/stored/${userId}`);
    },
    importPluggyItem        (itemId) {
        return this.stdPost('integration/pluggy/item/import', { id: itemId });
    },
    getAssociatedUsers      (userId) {
        return this.stdGet(`admin/entities/tree/${userId}`);
    },
    acceptTerms             () {
        const store         = getVueStore();
        if (!(store && store.getters)) {
            this.$toast.error('Falha de inicialização do cliente de serviço.');
            return Promise.resolve();
        }
        const user          = store.getters.$user;
        if (!user) {
            this.$toast.error('Falha de resgate de dados do usuário.');
            return Promise.resolve();
        }
        const userId        = store.getters.$user.id;
        if (!userId) {
            this.$toast.error('Falha de resgate de dados do usuário.');
            return Promise.resolve();
        }
        return this.stdPost(`user/terms/${userId}`)
            .then((resp) => {
                if (resp.success) {
                    user.acceptTerms = true;
                    store.commit('SET_CURRENT_USER', user);
                }
                return resp;
            });
    },
    ping                    () {
        return this.stdGet('health/ping');
    }
}