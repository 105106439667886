import  Vue     from    "vue";
import  Vuex    from    "vuex";

Vue.use(Vuex);

function prepare (value) {
    return typeof value === 'string' ?
        value :
        value === null || value === undefined ?
        'null' :
        JSON.stringify(value);
}

export default new Vuex.Store({
    state                           : {
        selectedUser                : undefined,
        idSelectedUser              : undefined,
        token                       : undefined,
        user                        : undefined,
        expires                     : undefined
    },
    mutations                       : {
        SET_TOKEN_SELECTED_USER     (state, payload)    {
            localStorage.removeItem("idSelectedUser");
            const content           = prepare(payload);
            localStorage.setItem("idSelectedUser", content);
            state.idSelectedUser    = content;
        },
        SET_SELECTED_USER           (state, payload)    {
            localStorage.removeItem("selectedUser");
            let content             = prepare(payload);
            localStorage.setItem("selectedUser", content);
            state.selectedUser      = content;
        },
        SET_CURRENT_TOKEN           (state, payload)    {
            sessionStorage.removeItem("token");
            const content           = prepare(payload);
            sessionStorage.setItem("token", content);
            state.token             = content;
        },
        SET_EXPIRES_TOKEN           (state, payload)    {
            const content           = prepare(payload);
            sessionStorage.removeItem("expires");
            sessionStorage.setItem("expires", content);
            state.expires           = content;
        },
        SET_CURRENT_USER            (state, payload)    {
            const content           = prepare(payload);
            sessionStorage.removeItem("user");
            sessionStorage.setItem("user", content);
            state.user              = content;
        },
    },
    actions                         : {
        PUT_ID_SELECTED_USER        ({commit}, payload) {
            commit('SET_TOKEN_SELECTED_USER', payload);
        },
        PUT_SELECTED_USER           ({commit}, payload) {
            commit('SET_SELECTED_USER', payload);
        }
    },
    getters                         : {
        $selectedUser               (state)             {
            return JSON.parse(state.selectedUser || localStorage.getItem('selectedUser'));
        },
        $idSelectedUser             (state)             {
            return state.idSelectedUser  || localStorage.getItem('idSelectedUser') ||
                JSON.parse(state.selectedUser || localStorage.getItem('selectedUser')).id ;
        },
        $token                      (state)             {
            return state.token || sessionStorage.getItem('token');
        },
        $expires                    (state)             {
            return state.expires || sessionStorage.getItem('expires');
        },
        $user                       (state)             {
            return JSON.parse(state.user || sessionStorage.getItem('user'));
        },
    },
});
