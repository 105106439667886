<template>
    <v-container>
        <h1>Termos de Uso KRISTAL CONNECT</h1>
        <br/>
        <p>
            Leia atentamente este documento antes de utilizar os serviços da KRISTAL,
            pois ao aceitá-lo, você estará celebrando um contrato vinculativo. Caso
            não concorde ou não possa cumprir com as disposições destes Termos, não
            utilize nossos Serviços. Se concordar, agradecemos por utilizar os
            Serviços da KRISTAL. Para algumas situações, precisaremos de seu
            consentimento explícito.
        </p>

        <h2>Requisitos para Utilização dos Serviços</h2>
        <br/>
        <p>
            Para usar os Serviços da KRISTAL, você precisa:
        </p>
        <ul>
            <li>
                Ter o poder de celebrar um contrato vinculativo e não ser impedido
                por qualquer lei;
            </li>
            <li>
                Ser residente de um país onde o Serviço está disponível.
            </li>
        </ul>
        <br />

        <h2>Nossos Serviços</h2>
        <br />
        <p>
            A KRISTAL oferece serviços ao Usuário e às empresas parceiras ("Empresa")
            que permitem consolidar as aplicações financeiras feitas em várias
            instituições financeiras em um único local, fornecendo uma visão consolidada
            e comparativa dos rendimentos segundo diferentes estratégias de alocação.
        </p>
        <p>
            A KRISTAL não realiza qualquer movimentação ou modificação nas contas
            bancárias informadas pelo Usuário. Limitamo-nos a acessar, por meio de
            parceiros regulados pelo Banco Central do Brasil, as instituições
            financeiras em modo leitura utilizando os dados credenciais fornecidos pelo
            Usuário, coletando os dados necessários para a prestação do serviço
            autorizado pelo Usuário.
        </p>

        <h2>Comunicações</h2>
        <br />
        <p>
            Podemos enviar anúncios de serviços, mensagens administrativas e outras
            informações. Você pode desativar algumas dessas comunicações.
        </p>

        <h2>Proteção à Privacidade e Propriedade Intelectual</h2>
        <br />
        <p>
            A KRISTAL utiliza parceiros para a coleta e processamento de seus dados,
            que possuem políticas de uso e privacidade próprias. Recomendamos que
            você leia, entenda e concorde com essas políticas antes de
            contratar os Serviços.
        </p>
        <p>
            Ao usar nossos Serviços, você concorda com o uso de seus dados conforme a
            Política de Privacidade e a legislação aplicável.
        </p>
        <p>
            Todos os programas de computador, incluindo a Plataforma, arquivos, textos,
            ícones, desenhos, vídeos, sons, marcas, logotipos, layouts, templates, etc.,
            são de titularidade da KRISTAL ou devidamente licenciados e estão protegidos
            por leis de propriedade intelectual e industrial. É proibida a cópia, alteração,
            reprodução ou qualquer outro tipo de utilização não autorizada, sujeitando os
            infratores a sanções civis e criminais.
        </p>

        <h2>Links para Terceiros</h2>
        <br />
        <p>
            A Plataforma pode incluir links para sites e aplicativos de terceiros. Isso não
            implica que a KRISTAL endossa ou possui qualquer ligação com os proprietários
            desses sites ou aplicativos, não sendo responsável pelo seu conteúdo.
        </p>

        <h2>Modificação e Cancelamento dos Serviços</h2>
        <br />
        <p>
            Estamos constantemente melhorando nossos Serviços e podemos incluir ou remover
            funcionalidades, ou suspender ou encerrar um Serviço por completo.
        </p>

        <h2>Garantias e Isenções de Responsabilidade</h2>
        <br />
        <p>
            Fornecemos nossos Serviços com um nível comercialmente razoável de capacidade
            e cuidado, mas não oferecemos garantias sobre os Serviços, exceto conforme
            previsto nestes Termos. Não somos responsáveis pela qualidade dos Serviços,
            funcionalidades específicas, ou pela sua disponibilidade e capacidade de atender
            suas necessidades. Fornecemos os Serviços "na forma em que estão".
        </p>

        <h2>Manutenção e Melhorias</h2>
        <br />
        <p>
            Devido à manutenção e melhoria do sistema, algumas funções podem ser desativadas
            temporariamente, e a KRISTAL não será responsabilizada por essa restrição temporária.
        </p>

        <h2>Responsabilidade pelos Serviços</h2>
        <br />
        <p>
            A KRISTAL garante que a Plataforma funcionará regularmente, exceto nos seguintes casos:
        </p>
        <ul>
            <li>
                Defeitos devido a mau uso, falta de conexão com a internet, bateria descarregada,
                defeito eletrônico do dispositivo, incompatibilidade de hardware, sistema
                operacional, memória insuficiente, etc.;
            </li>
            <li>
                Utilização ou violação por terceiros não autorizados, hacking, cracking,
                crawler, mashups;
            </li>
            <li>
                Problemas de companhias de serviços telefônicos que fornecem acesso à internet;
            </li>
            <li>
                Mau funcionamento do equipamento devido à queda ou indisponibilidade do servidor
                onde está hospedado o Gateway de SMS e o serviço de envio de e-mails.
            </li>
        </ul>
        <br />

        <h2>Modificações nos Termos</h2>
        <br />
        <p>
            Podemos modificar estes Termos ou quaisquer termos adicionais aplicáveis
            a um Serviço. Publicaremos avisos sobre modificações nesta página:
            <a title="Kristal Connect" href="https://kristalconnect.com.br/">
                https://kristalconnect.com.br/
            </a>.
            Se você não concordar com os termos alterados, deve descontinuar o uso do Serviço.
        </p>

        <h2>Disposições Gerais</h2>
        <br />
        <p>
            A não adoção imediata de providências em caso de descumprimento destes
            Termos não implica renúncia a quaisquer direitos. Caso alguma condição
            específica destes Termos não seja executável, isso não afetará as demais condições.
        </p>

        <h2>Legislação Aplicável</h2>
        <br />
        <p>
            Estes Termos são regidos pelas leis da República Federativa do Brasil.
            Qualquer disputa será resolvida no foro da Comarca de Belo Horizonte – M. G.
        </p>

        <v-form v-if="!previous">
            <v-row v-if="loading" class="justify-center align-center">
                <v-progress-circular :size="35"
                                    color="primary"
                                    indeterminate />
            </v-row>
            <v-row class="form-controls">
                <v-col>
                    <v-checkbox v-model="agree"
                                label="Concordo e aceito os termos especificados acima." />
                </v-col>
                <v-col class="text-center align-content-center">
                    <v-btn class="d-print-block"
                           @click="acceptTerms"
                           :disabled="!agree">Aceitar</v-btn>
                </v-col>
                <v-col class="text-center align-content-center">
                    <v-btn class="d-print-block"
                           @click="getOut">Cancelar</v-btn>
                </v-col>
            </v-row>
        </v-form>

        <p v-if="previous">
            <a href="#" @click="goHome">Voltar</a>
        </p>
    </v-container>
</template>

<style scoped>
@media screen and (max-width: 750px) {
    .form-controls {
        flex-direction: column;
    }
}
</style>

<script type="js">
import client from '@/services/PluggyService';

export default {
    data            :  () => ({
        loading     : false,
        agree       : false,
        previous    : false,
        client
    }),
    methods         :  {
        getOut      () {
            this.client.doLogout(this.$router);
        },
        goHome      () {
            this.$router.push("/home");
        },
        async acceptTerms () {
            const resp = await this.client.acceptTerms();
            if (!resp.success) {
                this.$toast.error(resp.message);
                return;
            }
            this.$toast.success(resp.message || 'Registro efetuado, redirecionando...');
            setTimeout(() => this.$router.push('/home'), 1500);
        }
    },
    mounted         () {
        const   user    = this.$store.getters.$user;
        this.previous   = !user || user.acceptTerms || false;
    }
}
</script>